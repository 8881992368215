<template>
  <div :class="ishome ? '' : 'wraper'" id="invite-award">
    <div
      class="good-btn"
      :class="diffTime(showTime) > 0 && !isPrepaid() ? 'good-btn-gray' : 'good-btn-blue'"
    >
      <div class="" v-if="isPrepaid()">预付款</div>
      <div class="" v-else>
        <div v-if="diffTime(showTime) > 0">
          <div v-if="diffTime(showTime) > 1 * 24 * 60 * 60 * 1000">
            {{ showTimeText(showTime) }}
          </div>
          <div v-else>
            倒计时<van-count-down
              class="countDown"
              millisecond
              format="HH:mm:ss"
              :time="diffTime(showTime)"
              @finish="finistTime"
            />
          </div>
        </div>
        <div v-else class="weigth">
          <span v-if="showStatus === 1">{{ type == 1 ? '立即购买' : '购买' }}</span>
          <span v-else>{{ is_pre_sale === 1 ? '预售结束' : '售卖已结束' }}</span>
          <!-- {{ showStatus === 1 ? btnstr : is_pre_sale === 1 ? '预售结束' : '已售罄' }} -->
        </div>
      </div>

      <div class="tips-orange" v-if="isPrepaid()">预付款得空投</div>
    </div>
    <div class="tips-red t-c" @click.stop="popShow = true" v-if="isPrepaid(1)">
      {{
        is_advance
          ? `你已付款，${getTime(showTime)}开售后系统帮抢`
          : `提前预付，${getTime(showTime)}开售后系统帮抢`
      }}<van-icon class="icon" name="question-o" />
    </div>
    <div class="tips-red t-c" @click.stop="popShow = true" v-if="pre_type == 2">
      <div v-if="diffTime(showTime) > 0 && diffTime(showTime) > 1 * 60 * 60 * 1000">
        创世优先购即将开启，敬请期待
      </div>
      <div v-if="diffTime(showTime) > 0 && diffTime(showTime) < 1 * 60 * 60 * 1000">
        创世优先购即将开启，敬请期待
      </div>
      <div v-if="diffTime(showTime) < 0 && showStatus === 1">创世优先购已开启</div>
    </div>
    <div class="tips-red t-c" @click.stop="popShow = true" v-if="pre_type == 1">
      <div v-if="diffTime(showTime) < 0 && showStatus === 1">指定特权优先购已开启</div>
      <div v-if="diffTime(showTime) > 0 && diffTime(showTime) < 1 * 60 * 60 * 1000">
        指定藏品优先购特权
      </div>
    </div>
    <van-popup class="vantPop" v-model:show="popShow">
      <div class="popup">
        <div class="content">
          <div class="title">预付款说明</div>
          <div>
            1、预付款是指提前支付全款购买自动抢购特权。当商品开售之后，系统将自动抢购一份商品，发放至“我的-我的藏品”中。同时你还可以继续参与线上抢购。
            <br />
            <br />
            2、若抢购过于火爆，系统未抢购成功，将退回自动抢购特权，可在“我的-我的特权”中查看。未使用的自动抢购特权支持全额退款。
          </div>
        </div>
        <div class="btn">
          <div @click.stop="popShow = false">知道了</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { reactive, toRefs, watch, onMounted } from 'vue';
import moment from 'moment';
export default {
  props: {
    time: {
      type: String,
      default: ''
    },
    status: {
      type: Number,
      default: 0
    },
    is_pre_sale: {
      type: Number,
      default: 0 //---- 预售 0,1
    },
    total_num: {
      type: Number,
      default: 0 //---- 库存总量（所有真实商品的总量和）
    },
    presale_start_time: {
      type: String
    }, // ---- 预售开始时间（可以进行预付费）
    presale_end_time: {
      type: String
      //---- 预售结束时间（不接受预付费）
    },
    is_advance: {
      type: Boolean,
      default: false //---- 是否已预付款
    },
    type: {
      type: Number,
      default: 2 //---- 藏品类型：1 普通藏品，2 盲盒藏品
    },
    pre_type: {
      type: Number,
      default: 0 // -- 提前购类型： 0 无，1 提前购特权&传说藏品， 2 创世
    },
    server_time: {
      type: Number,
      default: 0 //服务器时间
    }
  },
  emits: ['finistTime'],
  setup(props, ctx) {
    const state = reactive({
      showTime: props.time,
      showStatus: props.status,
      popShow: false, // 说明弹窗
      presale_start_time: props.presale_start_time,
      presale_end_time: props.presale_end_time,
      is_advance: props.is_advance,
      ishome: false,
      type: props.type,
      btnstr: props.type == 1 ? '立即购买' : '抽盲盒'
    });
    watch(
      () => props.type,
      type => {
        state.type = type;
      }
    );
    watch(
      () => props.time,
      time => {
        state.showTime = time;
      }
    );
    watch(
      () => props.status,
      status => {
        state.showStatus = status;
      }
    );
    watch(
      () => props.is_advance,
      is_advance => {
        state.is_advance = is_advance;
      }
    );
    watch(
      () => props.presale_start_time,
      presale_start_time => {
        state.presale_start_time = presale_start_time;
      }
    );
    watch(
      () => props.presale_end_time,
      presale_end_time => {
        state.presale_end_time = presale_end_time;
      }
    );
    onMounted(async () => {
      state.ishome = location.href.indexOf('/home') != -1;
    });
    const finistTime = () => {
      state.showTime = 0;
      ctx.emit('finistTime');
    };

    //是否为预付款时间
    const isPrepaid = num => {
      let now = new Date().getTime();
      //在预付款时间范围内
      let bl =
        moment(state.presale_start_time).valueOf() < now &&
        now < moment(state.presale_end_time).valueOf();
      let prepaidBool = num ? bl : bl && !state.is_advance;
      if (prepaidBool) {
        return true;
      } else {
        return false;
      }
    };

    // 获取格式时间
    const getTime = (time, format = 'MM月DD日 HH:mm') => {
      return moment(time).format(format);
    };

    const showTimeText = time => {
      if (moment(moment(time)).diff(moment(), 'day') > 7) {
        return '敬请期待';
      }
      // console.log(getTime(time) + '开售', '...2', props.is_pre_sale);
      let str;
      if (props.is_pre_sale == 1) {
        str = ` 预售`; //${props.total_num}份
      } else {
        str = ' 开售';
      }

      return getTime(time, 'MM月DD日 HH:mm') + str;
    };

    // 计算与当前时间时间差
    const diffTime = time => {
      // console.log(props.server_time);
      return moment(moment(time)).diff(moment(props.server_time), 'millisecond');
    };

    return {
      ...toRefs(state),
      getTime,
      showTimeText,
      diffTime,
      finistTime,
      isPrepaid
    };
  }
};
</script>
<style lang="scss">
#invite-award {
  .vantPop {
    background: transparent !important;
  }
}
</style>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.weigth {
  font-weight: 500;
}
.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;

  .content {
    padding: 16px;
    line-height: 26px;
    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .btn {
    div {
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      border-top: 1px solid #494359;
    }
  }
}
.wraper {
  background: linear-gradient(180deg, rgba(28, 23, 42, 0) 0%, #000000 100%);
  padding-bottom: 19px;
  width: 100%;
}
.tips-orange {
  position: absolute;
  top: -8px;
  right: 0;
  font-size: 12px;
  width: 94px;
  height: 24px;
  font-weight: 500;
  background-image: url('../imgs/comm/Slice35.png');
  background-size: 100%;
  color: $fc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  margin-left: 2px;
}
.t-c {
  text-align: center;
}
.tips-red {
  font-size: 12px;
  font-weight: 500;
  color: #e63f3f;
  margin-top: 7px;
  // width: 100vw;
}
.good-btn {
  position: relative;
  display: flex;
  margin: 0 auto;
  width: 272px;
  height: 45px;
  justify-content: center;
  align-items: center;

  .countDown {
    display: inline-block;
    color: #3bdbe8;
    margin-left: 10px;
  }
}
.good-btn-gray {
  background-image: url('../imgs/comm/pic_button_cmh_2.png');
  background-size: 100%;
  color: $fc;
}
.good-btn-blue {
  background-image: url('../imgs/comm/pic_button_cmh.png');
  background-size: 100%;
  color: $primaryc;
}
</style>
