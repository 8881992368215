<!--
 * 盲盒详情页
-->
<template>
  <div>
    <div class="seting-box">
      <div class="head">
        <div class="head-bg">
          <van-image width="100vw" :src="blindboxDetail.image_bg" />
        </div>
        <div class="shuoming" @click="instructions">
          <div class="next">说明 <van-icon name="arrow" size="14" /></div>
        </div>
      </div>

      <div class="content">
        <div class="box-title">
          <div class="">
            《{{ blindboxDetail.display_name }}》{{ blindboxDetail.collections_name }}
          </div>
          <div class="primary-color">
            <span class="f-16">¥</span
            ><span class="f-w f-30" v-if="blindboxDetail.price">{{
              blindboxDetail.price / 100
            }}</span>
          </div>
        </div>
        <div class="content-box">
          <div class="introduce" v-if="blindboxDetail.id <= 42">
            <div class="f-14 t-c" v-if="blindboxDetail.detail_type !== 2">
              本期盲盒<span>{{ blindboxDetail.is_pre_sale == 1 ? '预售' : '限量发售' }}</span>
              <span class="primary-color">{{ blindboxDetail.total_num }}</span
              >份，共<span class="primary-color">{{
                blindboxDetail.items && blindboxDetail.items.length
              }}</span
              >款{{ [18, 42].includes(blindboxDetail.id) ? '物品' : '藏品' }}
            </div>
            <div class="">
              <div class="intro-detail" @click="foldBtn = !foldBtn" :class="foldBtn ? 'text' : ''">
                <div class=""></div>
                <span :class="foldBtn ? 'two-line' : ''" id="intro">
                  {{ blindboxDetail.description }}
                </span>
                <van-icon
                  v-if="textOver && foldBtn"
                  class="open-icon"
                  :name="foldBtn ? 'arrow-down' : 'arrow-up'"
                />
              </div>
            </div>
          </div>

          <!-- 新人盲盒 -->
          <div class="" v-if="blindboxDetail.id == 18">
            <div class="newItem" v-for="(item, index) in contents" :key="index">
              <img :src="item" alt="" />
            </div>
          </div>
          <!-- 正常盲盒 -->
          <div class="" v-else>
            <div v-if="blindboxDetail.id <= 42">
              <div class="all-title t-c f-20">
                <!-- <div class="left-line"></div> -->
                藏品合集
                <!-- <div class="right-line"></div> -->
              </div>
              <div class="f-14 c-07 t-c">成功购买盲盒后，将随机获取以下藏品之一</div>
              <div class="card-list">
                <div
                  class="card-item"
                  v-for="(item, index) in blindboxDetail.items"
                  :key="index"
                  @click="toProduct(item.collections_id, item.id, item.reward_type)"
                >
                  <div class="img-box">
                    <div class="card-wrap">
                      <img class="card" fit="contain" :src="item.image_icon" alt="" />
                      <!-- <img class="card" fit="contain" :src="img1" alt="" /> -->
                    </div>
                    <div class="over" v-if="blindboxDetail.status == 2">
                      {{ blindboxDetail.is_pre_sale == 1 ? '预售结束' : '已结束' }}
                    </div>
                    <div class="msg-meng"></div>
                    <div class="icon-lable" v-if="item.reward_type == 1">
                      <img class="wid43" :src="levelsChange(item.levels)" />
                    </div>
                  </div>

                  <div class="msg" :class="{ flexcenter: blindboxDetail.id == 42 }">
                    <div class="f-12">{{ item.items_name }}</div>
                    <!-- item.reward_type == 1 && -->
                    <div class="flex-bt" v-if="blindboxDetail.id != 42">
                      <div class="primary-color">
                        <span class="f-10">¥</span
                        ><span class="f-w f-16">{{ item.price / 100 }}</span>
                      </div>
                      <div class="f-10 c-07">本期发售{{ item.total_num }}份</div>
                    </div>
                    <div class="block_items" v-if="blindboxDetail.id == 42"></div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 中奖概率 -->
            <div class="prob">
              <div class="title">数字藏品盲盒抽中概率说明</div>
              <div class="probBox">
                <div class="probItem" v-for="(item, index) in blindboxDetail.items" :key="index">
                  <div class="name">{{ item.items_name }}</div>
                  <div class="num">获得概率{{ item.order_rate }}%</div>
                  <div class="price">原价¥{{ item.price / 100 }}</div>
                  <img class="probBg" src="../imgs/comm/probBg.png" />
                </div>
              </div>
            </div>
            <div class="shuoming42" v-if="blindboxDetail.detail_type == 2">
              <img
                v-for="(item, index) in JSON.parse(blindboxDetail.contents)"
                :key="index"
                :src="item"
                alt=""
                srcset=""
              />
              <!-- <img
                v-if="blindboxDetail.id == 42"
                src="https://activity-resource.321mh.com/nft/notice/shenju_rule.png"
                alt=""
              />
              <img
                v-if="blindboxDetail.id == 43"
                src="https://activity-resource.321mh.com/nft/notice/pic_mhlb_xymh12x.png"
                alt=""
                srcset=""
              /> -->
            </div>
          </div>
        </div>
      </div>
      <van-popup v-model:show="show" style="background: #2f293f; border-radius: 8px" closeable>
        <div class="pop">
          <div class="pop-title">购买说明</div>
          <div class="pop-item">
            1.数字藏品非实物，发行数量有限，一经购买，将不支持退还，购买时请务必仔细核对。
          </div>
          <div class="pop-item">
            2.请您先完成实名认证，以保证顺利购买。请确保注册手机号与实名信息一致。一个身份证对应一个账号ID
          </div>
        </div>
      </van-popup>
      <div class="btnbox" @click="goToDetail(blindboxDetail.start_time)">
        <!-- <div class="btn_bg">{{ blindboxDetail.status == 2 ? "已售罄" : "抽盲盒" }}</div> -->
        <BoxBtn
          :status="blindboxDetail.status"
          :time="blindboxDetail.start_time"
          @finistTime="getDetail"
          :total_num="blindboxDetail.total_num"
          :is_pre_sale="blindboxDetail.is_pre_sale"
          :presale_start_time="blindboxDetail.presale_start_time"
          :presale_end_time="blindboxDetail.presale_end_time"
          :is_advance="blindboxDetail.is_advance"
          :server_time="server_time"
        />
      </div>
      <PageLoading :show="loading" />
      <PayType
        v-model:show="showPayTyoe"
        :box="blindboxDetail"
        :payStatus="payStatus"
        @close="showPayTyoe = false"
      />
      <OpenTheBox v-if="showOpenBox" @close="showOpenBox = false" :order="order" />
      <PayCbNew v-if="showNewBox" :order="order" />
    </div>
  </div>
</template>

<script>
import {
  reactive,
  onMounted,
  toRefs,
  onBeforeMount,
  nextTick,
  getCurrentInstance,
  onBeforeUpdate
} from 'vue';
import BoxBtn from '../components/BoxBtn.vue';
import { useRouter, useRoute } from 'vue-router';
import OpenTheBox from '@/components/OpenTheBox.vue';
import moment from 'moment';
import { boxDetail, checkorderispay } from '@/service/home';
import { localStorageUtil, util, nftUtils, domUtil } from '../utils';
import { Toast } from 'vant';
import PageLoading from '@/components/loading/PageLoading.vue';
import PayType from '@/components/newPayType';
import { shareHelper } from '@/helpers';
import { useStore } from 'vuex';
import PayCbNew from '@/components/PayCbNew.vue';
export default {
  components: {
    BoxBtn,
    PageLoading,
    PayType,
    OpenTheBox,
    PayCbNew
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    // const route = useRoute();
    const { proxy } = getCurrentInstance();
    const route = proxy.$route;
    const state = reactive({
      img1: 'https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1652341341261.jpg',
      blindboxDetail: {},
      cardList: [1, 2, 3, 4, 5],
      textOver: false, // 超过2行
      foldBtn: false, // 按钮默认显示缩起
      id: null,
      show: false,
      getCard: true,
      loading: true,
      showPayTyoe: false,
      isVerify: false, //
      showOpenBox: false, // 是否显示开盲盒弹窗
      order: '',
      payStatus: 1, //1--正常支付，2--预付款
      showNewBox: false,
      contents: [],
      server_time: 0
    });
    onBeforeUpdate(async () => {
      // 判断是否为支付回调

      if (localStorageUtil.get('orderId')) {
        // 判断是否有已经支付成功的订单
        let trade_no = localStorageUtil.get('orderId');
        localStorageUtil.del('orderId');
        // 是新人盲盒
        let isNew = localStorageUtil.get('isNew') ? localStorageUtil.get('isNew') : 0;
        localStorageUtil.del('isNew');
        const { data } = await checkorderispay({ trade_no });

        if (data) {
          state.order = trade_no;
          if (isNew == 1) {
            // 弹窗--新人盲盒
            // state.showNewBox = true;
          } else {
            state.showOpenBox = true;
          }

          // router.push({ path: `/boxopen/${trade_no}` });
        }
      }
      if (localStorageUtil.get('refresh')) {
        localStorageUtil.del('refresh');
        state.showBtn = false;
        setTimeout(() => {
          getDetail();
        }, 500);
      }
      // if (route.query.order) {
      //   console.log(route.query.order, '222');

      // }
    });

    onMounted(async () => {
      // localStorageUtil.set('orderId', 'TSTK20220928134312453');
      // localStorageUtil.set('isNew', 1);
      let id = route.params.id;
      state.id = id;
      let isLogin = nftUtils.isLogin();
      getDetail();
      if (isLogin) {
        await store.dispatch('refreshLoginInfo');

        state.isVerify = store.state.loginInfo ? !!store.state.loginInfo.id_verify : false;
      }
    });
    const getDetail = async () => {
      const { data, server_time } = await boxDetail({ id: state.id });
      state.blindboxDetail = data;
      state.contents = JSON.parse(data.contents);
      state.loading = false;
      state.server_time = server_time;
      window.scroll(0, 0);
      const shareConf = {
        title: '看漫-数字藏品',
        content: data.collections_name + '数字藏品限时限量抢购中...',
        image: '',
        link: location.href, // app分享链接
        callback: () => {
          // console.log('分享成功！！！');
          // Toast("分享成功！");
        }
      };
      // 设置微信分享和app内部分享(shareInfo：为动态获取数据。如果是静态的分享，则直接在data初始化中设置)
      shareHelper.init(shareConf);
      // 设置index.html中的头部分享信息
      domUtil.setDomShareMeta(shareConf);

      nextTick(() => {
        // setTimeout(()=>{
        const domRef = document.getElementById(`intro`);

        if (domRef) {
          const height = domRef.offsetHeight;
          console.log(height);
          if (+height > 100) {
            // 40 -- 2行的高度
            state.textOver = true;
            state.foldBtn = true;
          } else {
            state.textOver = false;
          }
        }
      });
      // },1000)
    };

    const goToDetail = async showTime => {
      nftUtils.setcnzzRecordByBtn('合集详情点击抽盲盒');
      let now = new Date().getTime();
      if (!localStorageUtil.get('accessToken')) {
        router.push({ path: `/login` });
        return;
      }
      if (!state.isVerify) {
        Toast('需要实名认证后才能购买');
        setTimeout(() => {
          router.push({ path: `/authentication` });
        }, 1000);
        return;
      }

      if (state.blindboxDetail.status == 2) {
        Toast(state.blindboxDetail.is_pre_sale == 1 ? '预售结束' : '已结束');
        return;
      }
      if (diffTime(showTime) > 0) {
        // 是否在支付预付款时间段
        if (
          !(
            moment(state.blindboxDetail.presale_start_time).valueOf() < now &&
            now < moment(state.blindboxDetail.presale_end_time).valueOf()
          ) ||
          state.blindboxDetail.is_advance
        ) {
          Toast('未到开售时间');
          return;
        } else {
          state.payStatus = 2;
        }
      }
      state.showPayTyoe = true;
    };

    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(moment(), 'millisecond');
    };

    const instructions = () => {
      nftUtils.setcnzzRecordByBtn('合集详情点击说明');
      state.show = !state.show;
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('../imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('../imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('../imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('../imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('../imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('../imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    const modifyPhone = async () => {
      // window.location.href = './modifyphone';
      router.push({ path: './modifyphone' });
    };
    const handleLogout = async () => {
      // const { resultCode } = await logout();
      // if (resultCode == 200) {
      //   setLocal('token', '');
      //   window.location.href = '/home';
      // }
    };

    const toProduct = (collections_id, id, reward_type) => {
      //    "reward_type":1   ---- 内容类型：1 藏品，2 积分，3 优先购特权, 4 抢先合成特权，5 谢谢惠顾，6 现金红包
      if (reward_type != 1) {
        return;
      }
      nftUtils.setcnzzRecordByBtn('合集详情点击跳转单品详情');
      router.push({ path: `/product/${collections_id}&${id}` });
    };

    return {
      ...toRefs(state),
      modifyPhone,
      handleLogout,
      toProduct,
      levelsChange,
      goToDetail,
      instructions,
      getDetail,
      diffTime
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.shuoming42 {
  img {
    width: 100%;
  }
}
.newItem {
  font-size: 0px;
  img {
    width: 100%;
  }
}
.seting-box {
  .pop-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
  }
  // .van-popup {
  //   background:#2F293F ;
  // }
  .pop {
    background: #2f293f;
    width: 300px;
    padding: 16px 25px;
    box-sizing: border-box;
    font-size: 16px;
    border-radius: 8px;
  }
  .pop-item {
    margin-bottom: 20px;
  }
  .open-icon {
    // float: right;
    //  margin-top: 21px;
    // clear: both;
  }
  .wid43 {
    width: 43px;
  }
  .text {
    display: flex;
    align-items: flex-end;
  }
  //   .text::before{
  //     content: '';
  //   float: right;
  //   width: 0px; /*设置为0，或者不设置宽度*/
  //  // height: 50px;/*先随便设置一个高度*/
  //   margin-bottom: -24px;
  //   background: red
  // }
  .btnbox {
    position: fixed;
    bottom: 76px; //19px;
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    z-index: 99;
    font-size: 16px;
  }
  .btn_bg {
    width: 268px;
    height: 45px;
    background-image: url('../imgs/comm/pic_button_cmh.png');

    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-size: 100% 100%;
    border-radius: 31px;
    font-size: 16px;
    color: #1c172a;
    z-index: 10;
  }
  .flex-bt {
    @include fj();
    align-items: flex-end;
    line-height: 16px;
    margin-top: 5px;
  }
  .block_items {
    height: 11px;
  }
  .card-list {
    @include fj();
    flex-wrap: wrap;
    margin-top: 22px;
  }
  .icon-lable {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 9;
    font-size: 0px;
  }
  .over {
    background: #58dedd;
    border-radius: 4px 4px 4px 4px;
    font-size: 12px;
    position: absolute;
    top: 10px;
    color: #1c172a;
    padding: 3px 6px;
    left: 7px;
  }
  .msg-meng {
    width: 168px;
    height: 30px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, #000000 100%);
    opacity: 1;
    position: absolute;
    bottom: 0;
  }
  .msg {
    padding: 0 10px 7px;
  }
  .flexcenter {
    text-align: center;
    margin-top: 10px;
  }
  .card-item {
    border-radius: 4px;
    overflow: hidden;
    background: #000000;
    margin-bottom: 22px;
    box-sizing: border-box;

    .img-box {
      position: relative;
    }
    .card-wrap {
      padding: 18px 32px 0;
      background-color: #1c172a;
      border: 1px solid #494359;
      border-bottom: none;
      box-sizing: border-box;
      border-radius: 4px 4px 0 0;
      font-size: 0px;
    }
    .card {
      width: 102px;
      min-width: 102px;
      // height: 166px;
      //  min-height: 166px;
    }
  }
  .all-title {
    background-image: url('../imgs/comm/Slice.png');
    width: 247px;
    background-size: 100% 100%;
    margin: 0 auto;

    margin-top: 39px;
    margin-bottom: 15px;
    // height: ;
  }
  .left-line {
    width: 69px;
    height: 3px;
    background: linear-gradient(306deg, #1a1526 0%, black 100%);
  }
  .right-line {
    width: 69px;
    height: 3px;
    background: linear-gradient(306deg, #1a1526 0%, #fff 100%);
    opacity: 1;
  }
  .two-line {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
    -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
    -webkit-line-clamp: 5; /** 显示的行数 **/
    overflow: hidden; /** 隐藏超出的内容 **/
  }
  .introduce {
    padding: 18px 0px 20px 0px;
    border-top: 1px solid #494359;
    border-bottom: 1px solid #494359;
  }
  .c-07 {
    color: rgba(255, 255, 255, 0.7);
  }
  .intro-detail {
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    margin-top: 12px;
  }
  .head-bg {
  }
  .head {
    position: relative;
  }
  .shuoming {
    position: absolute;
    bottom: 50px;
    right: 16px;
    font-size: 12px;
    font-weight: 500;
    color: #2f293f;
    @include bis('../imgs/comm/Slice5.png');
    opacity: 1;
    padding: 2px 12px;
    z-index: 9;
  }
  .box-title {
    @include fj();
    align-items: center;
    font-size: 18px;
    padding: 66px 20px 15px 20px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1c172a 46%);

    // box-sizing: border-box;
    // width: 100%;
  }
  .primary-color {
    color: $primary;
  }
  .f-16 {
    font-size: 16px;
  }
  .f-w {
    font-weight: bold;
  }
  .f-30 {
    font-size: 30px;
  }
  .f-14 {
    font-size: 14px;
  }
  .f-20 {
    font-size: 20px;
  }
  .t-c {
    text-align: center;
  }
  .f-10 {
    font-size: 10px;
  }
  .f-12 {
    font-size: 12px;
  }
  .content {
    position: relative;
    top: -66px;
    .content-box {
      // 140
      padding: 0 14px 0px 14px;
    }
  }

  .prob {
    text-align: center;
    .title {
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
      line-height: 16px;
      padding: 24px 0;
      border-top: 1px solid #494359;
    }

    .probBox {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 32px;
    }

    .probItem {
      width: 50%;
      position: relative;
      font-size: 0;
      margin-bottom: 16px;

      .name {
        position: absolute;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        top: 10px;
        left: 12px;
      }

      .num {
        position: absolute;
        bottom: 8px;
        left: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
      }

      img {
        width: 166px;
      }

      .price {
        position: absolute;
        bottom: 8px;
        right: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  // .save-btn {
  //   width: 80%;
  //   align-content: center;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   background-color: #aaaaaa;
  // }
}
</style>
